export const RATING_NAME = {
  checkin_process: "Checkin Process",
  communication: "Communication",
  compliance: "Compliance",
  profesionalism: "Profesionalism",
  service_quality: "Service Quality",
} as any;

export const LISTING_STATUS_NAME = {
  draft: "Draft",
  listed: "Listed",
  unlisted: "Unlisted",
} as any;

export const SUBSCRIPTION_STAGE_NAME = {
  active: "Active",
  pending_payment: "Pending Payment",
  inactive: "Inactive",
} as any;

export const LISTING_STATUS_USER = {
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected",
} as any;

export const LISTING_TYPE = {
  SCIENTIFIC_PROCESS: "scientific_process",
  EQUIPMENT: "equipment",
  SERVICE: "service",
} as any;

export const LISTING_TYPE_NAME = {
  all: "All",
  scientific_process: "Scientific Process",
  equipment: "Equipment",
  service: "Service",
} as any;
export const LISTING_PROCESS_STATUS = {
  listed: "Active",
  unlisted: "Inactive",
  draft: "Draft",
} as any;

export function getListingTypeName(type: string) {
  return LISTING_TYPE_NAME[type];
}

export const WORKING_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const defaultWorkingHours = [
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: false, start_time: "800", end_time: "1700" },
  { open: false, start_time: "800", end_time: "1700" },
];
export const defaultPagination = {
  page: 0,
  limit: 10,
};

export const supportedBrowsers: Record<string, number> = {
  Chrome: 90,
  Firefox: 80,
  Safari: 13,
  Edge: 90,
  Opera: 70,
};
export const appearancePaymentElement = {
  rules: {
    ".Tab": {
      border: "1px solid #959493",
      paddingTop: "30px",
      paddingBottom: "30px",
      paddingLeft: "15px",

      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
    },
    ".TabIcon": {
      fill: "#959493",
    },

    ".Tab:hover": {
      color: "var(--colorText)",
    },
    ".TabIconContainer": {
      marginBottom: "40px",
    },
    ".TabLabel": {
      paddingTop: "15px",
    },

    ".Tab--selected": {
      borderColor: "#736853",
      boxShadow: "none",
      color: "#726853",
      backgroundColor: "#faf7f2",
    },
    ".TabIcon--selected": {
      fill: "#726853",
    },

    ".Input--invalid": {
      boxShadow:
        "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
    },
    ".Label": {
      color: "#71706E",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "27px",
    },
    ".Input": {
      border: "1px solid #71706E",
      paddingTop: "18px",
      paddingBottom: "18px",
    },
    ".TermsText": {
      color: "#71706E",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: "400",
    },
    ".Label[for='Field-bankInput']": {
      display: "none",
      width: "24px",
    },

    // See all supported class names and selector syntax below
  },
};
